.swiper-custom-horizontal {
  display: flex;
  gap: 8px;
  padding: 8px;
  margin-top: 40px;
  background-color: #071520;
}

.swiper-custom-bullet {
  display: block;
  height: 8px;
  width: 8px;
  border-radius: 99px;
  background-color: #d9d9d9;
}

.swiper-custom-bullet.swiper-custom-bullet-active {
  background-color: #2d4f67;
  width: 24px;
}

.swiper-wrapper {
  margin-bottom: 20px;
}

.swiper-horizontal > .swiper-pagination-bullets {
  transform: translate(-50%, 5px);
  width: auto;
  backdrop-filter: blur(10px);
  border-radius: 99px;
  left: 50%;
}
